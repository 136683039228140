<template>
  <app-kebab-actions
    v-bind="$attrs"
    @showMenu="showMenu"
    v-on="$listeners">
    <ProductDuplicateV2
      v-for="type in [TYPE_DUPLICATE, TYPE_FOLLOW]"
      :key="type"
      :duplicate-type="type"
      :is-store-product-preload="isStoreProductPreload"
      :user-role="findUserRoleInLibraryForActiveHeader"
      :product-from-menu="item"
      :selected-products="selectedControlProducts"
      :duplicated-product-ids="selectedProductIds"
      :duplicated-collection-id="collectionId" />
    <ProductRevert
      v-if="item.mode === 'edit'"
      :product-id="item.id" />
    <ProductDelete
      :item="item"
      :product-ids="selectedProductIds"
      :collection-id="collectionId" />
    <ProductAddToProject
      :item="item" />
  </app-kebab-actions>
</template>
<script>
import {
  mapState, mapGetters,
} from 'vuex';
import ProductRevert from '@/components/Product/ProductRevert';
import ProductDelete from '@/components/Product/ProductDelete';
import {
  TYPE_DUPLICATE, TYPE_FOLLOW,
} from '@/constants';
import AppKebabActions from '@/components/App/AppKebabActions';
import ProductDuplicateV2 from '@/components/Product/ProductDuplicateV2';
import ProductAddToProject from '@/components/Product/ProductAddToProject';
export default {
  name: 'CollectionActionsProduct',
  components: {
    ProductDuplicateV2,
    AppKebabActions,
    ProductRevert,
    ProductDelete,
    ProductAddToProject,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    TYPE_DUPLICATE,
    TYPE_FOLLOW,
  }),
  computed: {
    ...mapState('Collections', ['selectedControlProductsIds']),
    ...mapGetters('Collections', ['selectedControlProducts']),
    ...mapGetters('UserRoles', ['findUserRoleInLibraryForActiveHeader']),
    selectedProductIds() {
      const { selectedControlProductsIds: ids, item } = this;
      if (!item) return [];
      return ids.length ? ids : [item.id];
    },
    collectionId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async showMenu(val) {
      if (!val) {
        this.$emit('update:item', null);
      }
    },
  },
};
</script>
