<template>
  <p @click="revert">
    <v-icon
      medium
      color="lightGrey">
      mdi-flip-to-back
    </v-icon>
    Revert
  </p>
</template>
<script>
import CollectionProductsApi from '@/services/graphql/collectionProducts';
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex';
export default {
  name: 'ProductRevert',
  props: {
    productId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions({
      changesOnUpdateClick: 'Collections/changesOnUpdateClick',
      handleError: 'handleError',
    }),
    async revert() {
      this.spinner(true);
      try {
        const { data } = await CollectionProductsApi.revertFollowedProduct({
          collectionId: this.$route.params.id,
          productId: this.productId,
          workspaceId: this.activeWorkspaceId,
        });
        this.changesOnUpdateClick(data.revertFollowedProduct.fields);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
