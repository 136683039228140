<template>
  <app-action-btn
    :access="canWorkWithDeleteProduct"
    :icon-src="require('@/assets/icons/delete-icon.svg')"
    :show-tooltip="!canWorkWithDeleteProduct.allowed"
    icon-height="17px"
    icon-width="17px"
    bottom
    @click="handleDeleteProduct">
    Delete
  </app-action-btn>
</template>
<script>
import {
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
export default {
  name: 'ProductDelete',
  props: {
    productIds: {
      type: Array,
      default: () => [],
    },
    collectionId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('UserRoles', ['canAddProductToCollection', 'findUserRoleInLibraryForActiveHeader']),
    canWorkWithDeleteProduct() {
      return this.canAddProductToCollection(this.findUserRoleInLibraryForActiveHeader);
    },
    removeText() {
      return `This will DELETE the Product(s) from this Collection. Are you sure?`;
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions({
      deleteProduct: 'Collections/deleteProduct',
    }),
    async handleDeleteProduct() {
      if (!this.canWorkWithDeleteProduct?.allowed) {
        return;
      }
      const response = await this.$openConfirm({
        text: this.removeText,
      });
      if (!response) {
        return;
      }
      const { collectionId, productIds } = this;
      this.spinner(true);
      await this.deleteProduct({
        collectionId,
        productIds,
      });
      this.spinner(false);
    },
  },
};
</script>
